import React from "react"

function FeatureSectionEN() {
  return (
    <section
      className="py-8 bg-lightgray md:bg-white overflow-hidden"
      id="features"
    >
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <img
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/6 opacity-25"
          width="300"
          height="784"
          src="/right-circles.svg"
          alt="right circles deco"
        />

        <div className="relative mt-4 lg:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center split-presentation">
          <div className="relative">
            <h4 className="text-2xl md:text-3xl leading-8 text-red-400 track font-semibold transform-uppercase">
              Learn together
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              You´re already working hard to master React, Vue or machine
              learning. Find a buddy to learn with to make E-Learning fun and
              easy. Learning should be social!
            </p>

            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      Learn faster
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Don´t know how to solve that problem? Don’t worry,
                      everyone does once in a while. Tell your buddy about it
                      and solve it together.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      Stay accountable
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Sometimes you just don´t feel like learning and that´s
                      alright, but don´t let that stop you from succeeding.
                      Course buddies help each other achieving their goals and
                      staying accountable.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">
                      Have fun
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Doing online courses together is a ton more fun!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0">
            <img
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden opacity-25"
              width="300"
              height="784"
              src="/left-circles.svg"
              alt="left circles deco"
            />
            <img
              className="relative mx-auto px-4 md:px-0"
              width="490"
              src="/undraw_real_time_collab.svg"
              alt=""
            />
          </div>
        </div>

        <img
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12 opacity-25"
          width="404"
          height="784"
          src="/left-circles.svg"
          alt="left circles deco"
        />

        <div className="relative mt-12 sm:mt-16 lg:mt-24 split-presentation">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h4 className="text-2xl text-red-400 md:text-3xl leading-8 font-semibold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                How it works
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                You´ve got two options: Look for someone who´s doing the same
                course as you are by using our search tool or create your own
                listing.
              </p>

              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Find a buddy
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Use our search tool to find courses you`re interested
                        in. E.g React Course on Udemy, Level: Advanced.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Create your own listing
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Can´t find a buddy who´s currently doing course X?
                        Create your own listing and let other people contact
                        you.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <img
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden opacity-25"
                width="784"
                height="404"
                src="/left-circles.svg"
                alt="left circles deco"
              />
              <img
                className="relative mx-auto px-4 md:px-0"
                width="490"
                src="/undraw_developer_activity.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSectionEN
