import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import FeatureSectionEN from "@components/FeatureSectionEN"
import alertTransition from "@css/transitions/alert.module.scss"
import myCuddy from "@images/mycuddy.png"
import myCuddyMobile from "@images/mycuddy_mobile.png"

function Home() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [ctaClicked, setCtaClicked] = useState(false)

  const scrollToId = (id: string): void => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    })
    setTimeout(() => {
      document.getElementById(id).focus()
    }, 800)
    setCtaClicked(true)
  }

  return (
    <>
      <Hero
        mobileNavOpen={mobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        scrollToId={scrollToId}
      />

      <FeatureSectionEN />
      <MyCuddy />
      <Footer />
    </>
  )
}

function Hero({ mobileNavOpen, setMobileNavOpen, scrollToId }) {
  return (
    <section className="relative bg-white overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
        <div className="relative h-full max-w-screen-xl mx-auto">
          <img
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2 opacity-25"
            width="300"
            height="784"
            src="/left-circles.svg"
            alt="left circles deco"
          />
          <img
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/6 lg:-translate-x-1/2 opacity-25"
            width="300"
            height="784"
            src="/right-circles.svg"
            alt="right circles deco"
          />
        </div>
      </div>

      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-16 xl:pb-20">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <img className="h-8 w-auto sm:h-10" src="/cuddy.svg" alt="" />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    onClick={(): void => setMobileNavOpen(true)}
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-8 w-8"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <a
                href="#features"
                className="font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                Features
              </a>
              <a
                href="#newsletter"
                className="ml-10 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                Newsletter
              </a>
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span className="inline-flex rounded-md shadow">
                <a
                  href="https://my.cuddy.app"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-blue-700 transition duration-150 ease-in-out"
                >
                  Find a buddy
                </a>
              </span>
            </div>
          </nav>
        </div>
        <CSSTransition
          in={mobileNavOpen}
          timeout={300}
          classNames={alertTransition}
          unmountOnExit
        >
          <MobileNav
            setMobileNavOpen={setMobileNavOpen}
            scrollToId={scrollToId}
          />
        </CSSTransition>

        <Content scrollToId={scrollToId} />
      </div>
    </section>
  )
}

function Content({ scrollToId }) {
  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="text-center">
        <h2 className="text-3xl tracking-tight leading-10 font-semibold text-gray-800 sm:text-3xl sm:leading-none md:text-6xl">
          Do online courses together
        </h2>
        <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Cuddy helps you find people doing the same online course so you can
          learn and grow together
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <a
              href="https://my.cuddy.app"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Find a buddy
              <span role="img" aria-label="Smart" className="ml-1">
                🤓
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

function Footer() {
  return (
    <section className="md:bg-white bg-lightgray">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center md:order-2">
          <a
            href="https://www.producthunt.com/posts/cuddy?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cuddy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=189184&theme=light"
              alt="Cuddy - Find a course buddy and do online courses together | Product Hunt Embed"
              style={{ width: "250px", height: "54px" }}
              width="250px"
              height="54px"
            />
          </a>
        </div>

        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base leading-6 text-gray-400">
            2020 Cuddy. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  )
}

function VideoPlayer() {
  return (
    <div className="mt-10 flex justify-center max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="w-full bg-yellow-400">test</div>
    </div>
  )
}

function Newsletter({ ctaClicked }) {
  const [submitted, setSubmitted] = useState(false)
  return (
    <section className="bg-lightgray md:bg-white py-16" id="newsletter">
      <div className="bg-blue-600">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-3xl leading-9 font-bold tracking-tight text-white sm:text-4xl sm:leading-10">
              We launched! Get early access!
              <span role="img" aria-label="rocket" className="ml-1">
                🚀
              </span>
            </h2>

            <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-300">
              Add your email to our newsletter and get early access to Cuddy!
            </p>

            {submitted && (
              <p className="max-w-3xl leading-6 text-green-400">
                Please wait a moment!
              </p>
            )}
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <form
              className="sm:flex"
              action="https://formspree.io/xknzkyka"
              method="POST"
              onSubmit={() => setSubmitted(true)}
            >
              <input
                aria-label="Email address"
                type="email"
                name="email"
                required
                id="bd-email"
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
              <input
                type="hidden"
                name="_next"
                value="https://cuddy.app/thank-you"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out"
                >
                  Request access
                </button>
              </div>
            </form>
            <p className="mt-3 text-sm leading-5 text-blue-300">
              We care about the protection of your data. Read our
              <a
                href="https://www.iubenda.com/privacy-policy/21951540"
                className="text-white font-medium underline ml-1"
              >
                Privacy Policy.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function MyCuddy() {
  return (
    <section className="bg-lightgray md:bg-white py-16" id="newsletter">
      <div className="bg-blue-600">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:block lg:items-center">
          <div className="lg:flex-1 md:px-24">
            <div className="flex items-center justify-center">
              <h2 className="text-3xl leading-9 text-center font-bold tracking-tight text-white sm:text-5xl sm:leading-10 py-8">
                We launched!
              </h2>
              <a
                href="https://my.cuddy.app"
                className="ml-4 hidden md:block px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out"
              >
                Find a buddy now
              </a>
            </div>

            <div className="shadow-xs">
              <img
                src={myCuddy}
                alt="my cuddy app display"
                className="rounded-lg shadow-lg hidden md:block"
              />
              <img
                src={myCuddyMobile}
                alt="my cuddy app display"
                className="rounded-lg shadow-lg md:hidden"
              />
            </div>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <a
              href="https://my.cuddy.app"
              className="md:hidden w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out"
            >
              Find a buddy
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

function MobileNav({ setMobileNavOpen, scrollToId }) {
  return (
    <div className="absolute top-0 inset-x-0 p-2 md:hidden">
      <div
        className="rounded-lg shadow-md transition transform origin-top-right"
        x-show="open"
      >
        <div className="rounded-lg bg-white shadow-xs overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img className="h-8 w-auto" src="/cuddy.svg" alt="" />
            </div>
            <div className="-mr-2">
              <button
                onClick={(): void => setMobileNavOpen(false)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3">
            <a
              href="#features"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            >
              Features
            </a>
            <a
              href="#newsletter"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            >
              Newsletter
            </a>
          </div>
          <div>
            <a
              href="https://my.cuddy.app"
              className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out"
            >
              Find a buddy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
